











































































import { defineComponent, reactive } from '@vue/composition-api'
import validator from 'validator'

export default defineComponent({
  props: {
    value: {
      type: Object,
      required: false,
      default: () => ({
        domain: '',
        name: '',
        title: '',
        dbname: '',
        wpuser: '',
        wppass: '',
        wpemail: ''
      })
    },
    eventType: {
      type: String,
      required: false
    }
  },

  setup(_, { root }) {

    const state = reactive({
      showPassword: false
    })

    const rules = {
      email: [
        (v: string) => !v || validator.isEmail(v) || root.$tc('layout.misc.validEmailAddress')
      ],
      password: [
        (v: string) => !!v || root.$tc('panel.event.webiste.generate.enterPassword'),
        (v: string) => v.length >= 8 || root.$tc('panel.event.webiste.generate.charactersRule')
      ],
      dbname: [
        (v: string) => !v || validator.isAlphanumeric(v) || root.$tc('panel.event.webiste.generate.databaseRule')
      ],
      domain: [
        (v: string) => !v || validator.isFQDN(v) || root.$tc('panel.event.webiste.generate.domainRule')
      ],
      name: [
        (v: string) => !v || validator.isAlphanumeric(v) || root.$tc('panel.event.webiste.generate.correctFolderName')
      ]
    }

    const isLetter = (e: any) => {
      const char = String.fromCharCode(e.keyCode);
      // eslint-disable-next-line no-useless-escape
      if(/^[A-Za-z0-9\-\s]*$/.test(char)) return true;
      else e.preventDefault();
    }

    return { state, rules, isLetter }
  }
})
